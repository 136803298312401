import axiosConfig from '../config/axiosConfig';

const getRatingInfo = async (driverId, orderId) => {
  const response = await axiosConfig.get(`/ratings/info?driverId=${driverId}&orderId=${orderId}`);
  return response.data.result;
};

const postRating = async body => {
    const response = await axiosConfig.post(`/ratings`, body);
    return response.data;
  };

export {
    getRatingInfo,
    postRating,
};
