import React from 'react'
import logo from './lacher-logo.svg';
import './App.css';
import 'react-rater/lib/react-rater.css'
import Button from './components/Button';
import PropTypes from 'prop-types'
import Rater from 'react-rater'
import { StaggeredMotion, spring, presets } from 'react-motion'
import { getRatingInfo, postRating } from './api';

const Star = ({ willBeActive, isActive, style }) => {
  const fill = isActive ? '#fc6' : willBeActive ? '#ffdd99' : '#e3e3e3'
  return (
    <svg viewBox="0 0 19.481 19.481" width="36" height="36" style={style}>
      <path
        fill={fill}
        d="m10.201,.758l2.478,5.865 6.344,.545c0.44,0.038 0.619,0.587 0.285,0.876l-4.812,4.169 1.442,6.202c0.1,0.431-0.367,0.77-0.745,0.541l-5.452-3.288-5.452,3.288c-0.379,0.228-0.845-0.111-0.745-0.541l1.442-6.202-4.813-4.17c-0.334-0.289-0.156-0.838 0.285-0.876l6.344-.545 2.478-5.864c0.172-0.408 0.749-0.408 0.921,0z"
      />
    </svg>
  )
}

Star.propTypes = {
  isActive: PropTypes.bool,
  willBeActive: PropTypes.bool,
  style: PropTypes.any
}

function App() {
  const [driver,setDriver] = React.useState(false);
  const [order,setOrder] = React.useState(false);
  const [submit,setSubmit] = React.useState(false);
  const [rating,setRating] = React.useState(0);
  const [feedback,setFeedback] = React.useState('');
  const [defaultStyles,setDefaultStyles] = React.useState([{ x: 0 }, { x: 0 }, { x: 0 }, { x: 0 }, { x: 0 }]);
  const [shouldStart, setShouldStart] = React.useState(false);

  const queryParameters = new URLSearchParams(window.location.search)
  const driverId = queryParameters.get("driverId")
  const orderId = queryParameters.get("orderId")
  
  React.useEffect(() => {
    const getRatingData = async () => {
      const result = await getRatingInfo(driverId,orderId);

      if (result.exist){
        setSubmit(true);
      }else {
        setDriver(result.ratingInfo.driver)
        setOrder(result.ratingInfo.order)
      }
    };
    getRatingData();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      setShouldStart(true);
      }, 0)  
}, []); 

  const handleRate = ({ rating }) => {
    const defaultStyles = [
      { x: 0 },
      { x: 0 },
      { x: 0 },
      { x: 0 },
      { x: 0 }
    ].map((style, i) => {
      if (rating < i + 1) {
        return { x: 1 }
      }
      return style
    })

    setRating(rating);
    setDefaultStyles(defaultStyles);
  }

  const getStyle = (prevInterpolatedStyles) => {
    if (!shouldStart) {
      return prevInterpolatedStyles.map(() => {
        return { x: 0 }
      })
    } else {
      if (rating) {
        return prevInterpolatedStyles.map((_, i) => {
          if (i + 1 > rating) {
            return { x: 1 }
          }
          return i === 0
            ? { x: spring(1, presets.gentle) }
            : { x: spring(prevInterpolatedStyles[i - 1].x, presets.gentle) }
        })
      } else {
        return prevInterpolatedStyles.map((_, i) => {
          return i === 0
            ? { x: spring(1, presets.wobbly) }
            : { x: spring(prevInterpolatedStyles[i - 1].x, presets.wobbly) }
        })
      }
    }
  }

  const handleFeedbackChange = event => {
    // 👇️ access textarea value
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await postRating({
      driverId ,
      orderId,
      rating,
      comments: feedback
    });
    setSubmit(true)
  };

  return (
    <div className="App">
      <div className="container">
        <img src={logo} className="App-logo" alt="logo" />
        {submit ? <p className="main-text">Thank you for your feedback!</p> : <><p className="main-text">Rate your delivery</p>
          <div className="delivery-details">
            <div className="secondary-text">Driver: {driver.name}</div>
            <div className="secondary-text">OrderID: {order}</div>
          </div>

          <div className="animated-rater">
        <StaggeredMotion
          defaultStyles={defaultStyles}
          styles={getStyle.bind(this)}
          key={defaultStyles.filter(style => style.x === 1).length}
        >
          {interpolatingStyles => (
            <Rater
              total={5}
              rating={rating}
              onRate={handleRate.bind(this)}
            >
              {interpolatingStyles.map((style, i) => (
                <Star style={{ transform: `scale(${style.x})` }} key={i} />
              ))}
            </Rater>
          )}
        </StaggeredMotion>
      </div>
                <div className="additional-feedback">
            <label className="label-text" for="feedback">Additional feedback:</label>
            <textarea rows={10} cols={30} value={feedback}
        onChange={handleFeedbackChange}/>
          </div>

          <Button onClick={handleSubmit} text="Submit" /></>}
        </div>


    </div>
  );
}

export default App;
