import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const X_API_KEY = process.env.REACT_APP_MAIN_API_KEY;
const axiosConfig = axios.create({
  baseURL: BASE_URL
});

// axiosConfig.defaults.headers.common['x-api-key'] = X_API_KEY;

export default axiosConfig;
